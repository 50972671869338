<template>
  <v-card
    outlined
    elevation="3"
    min-height="550"
    flat
    class="request-builder pa-md-5 pa-sm-2 rounded-10"
  >
    <v-card-title class="mb-2">
      <h2 class="primary--text font-weight-bold text-center heading">
        Client Request Template Builder
      </h2>
      <v-spacer />
      <v-btn
        elevation="1"
        @click="$router.push({ name: 'templates' })"
        color="primary"
        class="mx-1 px-5 text-none"
      >
        Back
      </v-btn>
    </v-card-title>
    <v-card-text class="body">
      <v-row dense align="center" justify="center">
        <v-col cols="12">
          <label class="primary--text subtitle-2">
            Request Template Name *
          </label>
          <v-text-field
            dense
            :rules="requiredRules"
            outlined
            hide-details="auto"
            v-model="request_template_name"
            prepend-inner-icon="mdi-format-color-highlight"
          />
        </v-col>
        <v-col cols="12" md="12">
          <label
            for="privacy-checkbox"
            class="d-flex align-center justify-start"
          >
            <v-checkbox
              v-model="is_public"
              id="privacy-checkbox"
              hide-details="auto"
            ></v-checkbox>
            <span class="mt-4 subtitle-2 primary--text ml-1">
              Make it a public template
            </span>
          </label>
          <small>
            Public templates are visible to all {{ settings.SiteName }} users.
            They are allowed to copy/import this template for their own use.
          </small>
        </v-col>
        <v-col cols="12">
          <label class="primary--text subtitle-2 my-4">
            Template Structures *
          </label>
          <Builder v-model="structures"></Builder>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="footer">
      <v-row align="center" justify="center">
        <v-btn
          depressed
          @click="$router.push({ name: 'templates' })"
          color="primary"
          class="mx-2 text-none px-5"
        >
          Cancel
        </v-btn>
        <v-btn
          depressed
          color="primary"
          class="text-none px-5"
          :loading="btnloading"
          :disabled="!is_valid"
          @click="saveTemplate"
        >
          {{ button_label }}
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import Builder from '@/modules/Forms-Builder/components/Builder/Builder.vue'
import request from '@/services/axios_instance'

export default {
  name: 'RequestBuilder',

  components: {
    Builder
  },
  data: () => ({
    existing: [],
    is_public: false,
    structures: [],
    request_template_name: 'Untitled Request Template',
    isEdit: false,
    requestId: null,
    requestToEdit: null,
    btnloading: false
  }),
  computed: {
    paths() {
      return [
        {
          text: 'Dashboard',
          disabled: false,
          route: { name: 'default-content' }
        },
        { text: 'Templates', disabled: false, route: { name: 'templates' } },
        {
          text: !this.isEdit ? 'New Request' : 'Edit Request',
          disabled: true,
          route: null
        }
      ]
    },
    is_valid() {
      return this.validate(false)
    },
    button_label() {
      return this.isEdit ? 'Update Template' : 'Save New Template'
    }
  },
  created() {
    //this.getExistingRequestTemplates()
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.isEdit = true
      this.requestId = this.$route.params.id
      this.getRequestTemplate(this.$route.params.id)
    }
    this.$event.$emit('path-change', this.paths)
  },
  methods: {
    getExistingRequestTemplates() {
      request.get(`api/template/requests`).then(({ data }) => {
        this.existing = data
      })
    },
    getRequestTemplate(id) {
      request.get(`api/template/requests/${id}`).then(
        ({ data }) => {
          this.requestToEdit = data
          this.is_public = this.requestToEdit.public
          this.request_template_name = this.requestToEdit.name
          this.structures = this.requestToEdit.template
        },
        (error) => {
          this.appSnackbar(`No request template found for id: ${id}`, 'error')
          this.$router.push({ name: 'templates' })
        }
      )
    },
    validate(withMessage) {
      if (this.structures.length === 0) {
        if (withMessage) this.appSnackbar('No content found!', 'error')
        return false
      }
      if (!this.request_template_name) {
        if (withMessage)
          this.appSnackbar('Request Template Name is required', 'error')
        return false
      }
      return true
    },
    saveTemplate() {
      if (this.validate(true)) {
        this.btnloading = true
        if (this.isEdit) {
          request
            .put(`api/template/requests/${this.requestId}`, {
              name: this.request_template_name,
              data: this.structures,
              is_public: this.is_public
            })
            .then(({ data }) => {
              this.appSnackbar(data.message)
              this.$router.push({ name: 'templates' })
            })
            .finally(() => (this.btnloading = false))
        } else {
          request
            .post(`api/template/requests`, {
              name: this.request_template_name,
              data: this.structures,
              is_public: this.is_public
            })
            .then(({ data }) => {
              this.appSnackbar(data.message)
              this.$router.push({ name: 'templates' })
            })
            .finally(() => (this.btnloading = false))
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.request-builder {
  .body {
    margin-bottom: 100px;
  }
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
  }
}
</style>
